import { render, staticRenderFns } from "./reg-custom.vue?vue&type=template&id=1b1104da&scoped=true&"
import script from "./reg-custom.vue?vue&type=script&lang=js&"
export * from "./reg-custom.vue?vue&type=script&lang=js&"
import style0 from "./reg-custom.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./reg-custom.vue?vue&type=style&index=1&id=1b1104da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b1104da",
  null
  
)

export default component.exports