var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reg"},[_c('div',{staticClass:"reg-header"},[_c('topNav')],1),_c('div',{staticClass:"reg-main"},[_c('div',{staticClass:"m-steps"},[_c('Steps',{attrs:{"current":_vm.curIndex}},[_c('Step',{attrs:{"title":"Email verification"}}),_c('Step',{attrs:{"title":"Add your Profile"}}),_c('Step',{attrs:{"title":"Registration completed"}})],1)],1),_c('div',{staticClass:"admin-add"},[_c('div',{staticClass:"admin-add-content"},[_c('Form',{ref:"formModel",staticClass:"F",attrs:{"model":_vm.formModel,"label-width":260}},[_vm._l((_vm.formData),function(item,index){return [(item.tagType === 0 && item.label === 'Name')?_c('FormItem',{key:index,ref:'model' + index,refInFor:true,attrs:{"label":item.label}},[_c('Input',{staticStyle:{"width":"300px"},attrs:{"type":"text","placeholder":item.attributes.placeholder || 'Please enter'},model:{value:(_vm.formModel.items[index]['model' + index]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index], 'model' + index, $$v)},expression:"formModel.items[index]['model' + index]"}})],1):_vm._e(),(item.tagType === 0 && item.label === 'E-mail')?_c('FormItem',{key:index,ref:'model' + index,refInFor:true,attrs:{"label":item.label,"prop":'items.' + index + '.model' + index,"rules":{
                required: item.attributes.checkOptions.require,
                message: "The validation failed",
                trigger: 'blur',
                type: 'email',
              }}},[_c('Input',{staticStyle:{"width":"300px"},attrs:{"type":"text","placeholder":item.attributes.placeholder || 'Please enter',"disabled":_vm.email ? true : false},model:{value:(_vm.formModel.items[index]['model' + index]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index], 'model' + index, $$v)},expression:"formModel.items[index]['model' + index]"}})],1):_vm._e(),(item.tagType === 0 && item.label === 'Password' && !_vm.user.userId)?_c('FormItem',{key:index,ref:'model' + index,refInFor:true,attrs:{"required":item.attributes.checkOptions.require,"label":item.label,"prop":'items.' + index + '.model' + index,"rules":{
                required: item.attributes.checkOptions.require,
                message: "The validation failed",
                trigger: 'blur',
              }}},[_c('Input',{staticStyle:{"width":"300px"},attrs:{"type":"text","placeholder":item.attributes.placeholder || 'Please enter'},model:{value:(_vm.formModel.items[index]['model' + index]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index], 'model' + index, $$v)},expression:"formModel.items[index]['model' + index]"}})],1):_vm._e(),(item.tagType === 0 && item.label === 'Company')?_c('FormItem',{key:index,ref:'model' + index,refInFor:true,attrs:{"label":item.label}},[_c('Input',{staticStyle:{"width":"300px"},attrs:{"type":"text","placeholder":item.attributes.placeholder || 'Please enter'},model:{value:(_vm.formModel.items[index]['model' + index]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index], 'model' + index, $$v)},expression:"formModel.items[index]['model' + index]"}})],1):_vm._e(),(item.tagType === 0 && item.label === 'Job title')?_c('FormItem',{key:index,ref:'model' + index,refInFor:true,attrs:{"label":item.label}},[_c('Input',{staticStyle:{"width":"300px"},attrs:{"type":"text","placeholder":item.attributes.placeholder || 'Please enter'},model:{value:(_vm.formModel.items[index]['model' + index]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index], 'model' + index, $$v)},expression:"formModel.items[index]['model' + index]"}})],1):_vm._e(),(item.tagType === 0 && item.label === 'Mobile')?_c('FormItem',{key:index,ref:'model' + index,refInFor:true,attrs:{"required":item.attributes.checkOptions.require,"label":item.label}},[_c('Input',{staticStyle:{"width":"300px"},attrs:{"type":"text","placeholder":item.attributes.placeholder || 'Please enter'},model:{value:(_vm.formModel.items[index]['model' + index]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index], 'model' + index, $$v)},expression:"formModel.items[index]['model' + index]"}})],1):_vm._e(),(item.tagType === 1)?_c('FormItem',{key:index,ref:'model' + index,refInFor:true,attrs:{"label":item.label,"prop":'items.' + index + '.model' + index,"rules":{
                required: item.attributes.checkOptions.require,
                message: "The validation failed",
                trigger: 'blur',
              }}},[_c('Input',{staticStyle:{"width":"300px"},attrs:{"type":"textarea","rows":4,"placeholder":item.attributes.placeholder || 'Please enter'},model:{value:(_vm.formModel.items[index]['model' + index]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index], 'model' + index, $$v)},expression:"formModel.items[index]['model' + index]"}})],1):_vm._e(),(item.tagType === 2)?_c('FormItem',{key:index,ref:'model' + index,refInFor:true,attrs:{"label":item.label,"prop":'items.' + index + '.model' + index,"rules":[
                {
                  required: item.attributes.checkOptions.require,
                  type: 'number',
                  message: "The validation failed",
                  trigger: 'blur',
                } ]}},[_c('InputNumber',{staticStyle:{"width":"300px"},attrs:{"max":99999,"min":0},model:{value:(_vm.formModel.items[index]['model' + index]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index], 'model' + index, $$v)},expression:"formModel.items[index]['model' + index]"}})],1):_vm._e(),(item.tagType === 3)?_c('FormItem',{key:index,ref:'model' + index,refInFor:true,attrs:{"label":item.label,"prop":'items.' + index + '.model' + index,"rules":{
                required: item.attributes.checkOptions.require,
                message: "The validation failed",
                trigger: 'change',
              }}},[_c('Select',{staticStyle:{"width":"300px"},model:{value:(_vm.formModel.items[index]['model' + index]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index], 'model' + index, $$v)},expression:"formModel.items[index]['model' + index]"}},_vm._l((item.columns),function(val){return _c('Option',{key:val,attrs:{"value":val}},[_vm._v(_vm._s(val))])}),1)],1):_vm._e(),(item.tagType === 4)?_c('FormItem',{key:index,ref:'model' + index,refInFor:true,attrs:{"label":item.label,"prop":'items.' + index + '.model' + index,"rules":{
                required: item.attributes.checkOptions.require,
                message: "The validation failed",
                trigger: 'change',
              }}},[_c('RadioGroup',{staticStyle:{"width":"300px"},model:{value:(_vm.formModel.items[index]['model' + index]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index], 'model' + index, $$v)},expression:"formModel.items[index]['model' + index]"}},_vm._l((item.attributes.options),function(opt){return _c('Radio',{key:opt.value,attrs:{"label":opt.value}},[_vm._v(_vm._s(opt.value))])}),1)],1):_vm._e(),(item.tagType === 5)?_c('FormItem',{key:index,ref:'model' + index,refInFor:true,attrs:{"label":item.label,"prop":'items.' + index + '.model' + index,"rules":{
                required: item.attributes.checkOptions.require,
                message: "The validation failed",
                trigger: 'change',
              }}},[_c('CheckboxGroup',{staticStyle:{"width":"300px"},model:{value:(_vm.formModel.items[index]['model' + index]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index], 'model' + index, $$v)},expression:"formModel.items[index]['model' + index]"}},_vm._l((item.attributes.options),function(opt){return _c('Checkbox',{key:opt.value,attrs:{"label":opt.value}},[_vm._v(_vm._s(opt.value))])}),1)],1):_vm._e(),(item.tagType === 6)?_c('FormItem',{key:index,ref:'model' + index,refInFor:true,attrs:{"label":item.label,"prop":'items.' + index + '.model' + index,"rules":{
                required: item.attributes.checkOptions.require,
                type: 'date',
                message: "The validation failed",
                trigger: 'change',
              }}},[_c('DatePicker',{staticStyle:{"width":"300px"},attrs:{"type":"date","placeholder":item.attributes.placeholder || '请选择日期'},model:{value:(_vm.formModel.items[index]['model' + index]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index], 'model' + index, $$v)},expression:"formModel.items[index]['model' + index]"}})],1):_vm._e(),(item.tagType === 7)?[_c('FormItem',{key:'start' + index,ref:'model' + index,refInFor:true,attrs:{"label":item.childrens[0].label,"prop":'items.' + index + '.model' + index + '.start',"rules":{
                  required: item.attributes.checkOptions.require,
                  type: 'date',
                  message: "The validation failed",
                  trigger: 'change',
                }}},[_c('DatePicker',{staticStyle:{"width":"300px"},attrs:{"type":"date","placeholder":item.attributes.placeholder || '请选择日期'},model:{value:(_vm.formModel.items[index]['model' + index].start),callback:function ($$v) {_vm.$set(_vm.formModel.items[index]['model' + index], "start", $$v)},expression:"formModel.items[index]['model' + index].start"}})],1),_c('FormItem',{key:'end' + index,ref:'model' + index,refInFor:true,attrs:{"label":item.childrens[1].label,"prop":'items.' + index + '.model' + index + '.end',"rules":{
                  required: item.attributes.checkOptions.require,
                  type: 'date',
                  message: "The validation failed",
                  trigger: 'change',
                }}},[_c('DatePicker',{staticStyle:{"width":"300px"},attrs:{"type":"date","placeholder":item.attributes.placeholder || '请选择日期'},model:{value:(_vm.formModel.items[index]['model' + index].end),callback:function ($$v) {_vm.$set(_vm.formModel.items[index]['model' + index], "end", $$v)},expression:"formModel.items[index]['model' + index].end"}})],1)]:_vm._e(),(item.tagType === 10)?_c('FormItem',{key:index,attrs:{"label-width":260}},[_c('p',{staticClass:"tipInfo",staticStyle:{"width":"300px"}},[_vm._v(" "+_vm._s(item.attributes.value)+" ")])]):_vm._e(),(item.tagType === 11 && item.label === 'Region')?_c('FormItem',{key:index,attrs:{"label":item.label,"prop":'items.' + index + '.model' + index,"rules":{
                required: item.attributes.checkOptions.require,
                message: "The validation failed",
                trigger: 'change',
              },"label-width":260}},[_c('Select',{staticStyle:{"width":"300px"},attrs:{"filterable":""},on:{"on-change":_vm.onConfirm},model:{value:(_vm.formModel.items[index]['model' + index]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index], 'model' + index, $$v)},expression:"formModel.items[index]['model' + index]"}},_vm._l((_vm.country),function(item){return _c('Option',{key:item.nameEn,attrs:{"value":item.nameEn}},[_vm._v(_vm._s(item.nameEn))])}),1)],1):_vm._e(),(item.tagType === 11 && item.label === 'Interest')?_c('FormItem',{key:index,attrs:{"label":item.label,"rules":[
                {
                  //required: item.attributes.checkOptions.require,
                  type: 'string',
                  //validator: InterestValid,
                  min: 1,
                } ],"label-width":260}},_vm._l((_vm.formModel.items[index]['model' + index]),function(item,idx){return _c('Row',{key:_vm.cateKey + '' + idx,staticStyle:{"margin-bottom":"5px"}},[_c('Col',{attrs:{"span":"12"}},[_c('Cascader',{key:'categoryArr' + idx,ref:'categoryArr' + idx,refInFor:true,attrs:{"filterable":"","data":_vm.categoryList},on:{"on-change":function($event){return _vm.changeCategoryArr('items.' + index + '.model' + index)}},model:{value:(_vm.formModel.items[index]['model' + index][idx]),callback:function ($$v) {_vm.$set(_vm.formModel.items[index]['model' + index], idx, $$v)},expression:"formModel.items[index]['model' + index][idx]"}})],1),_c('Col',{staticStyle:{"text-align":"center"},attrs:{"span":"2"}},[(idx === 0)?_c('Icon',{staticStyle:{"color":"#2d8cf0","font-size":"20px","cursor":"pointer"},attrs:{"type":"ios-add-circle-outline"},on:{"click":function($event){return _vm.addCategoryArrr(index)}}}):_c('Icon',{staticStyle:{"color":"red","font-size":"20px","cursor":"pointer"},attrs:{"type":"ios-remove-circle-outline"},on:{"click":function($event){return _vm.delCategoryArrr(index, idx)}}})],1)],1)}),1):_vm._e()]}),_c('FormItem',{attrs:{"label-width":260}},[_c('Button',{staticClass:"btn",staticStyle:{"width":"300px"},attrs:{"type":"primary","size":"large","loading":_vm.loading},on:{"click":function($event){return _vm.handleSubmit('formModel')}}},[_vm._v("next")])],1)],2)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }